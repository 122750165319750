<template>
  <div class="section">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="团购类别"
        :rules="[
          { required: true, message: '请选择团购类别', trigger: 'blur' },
        ]"
        prop="buyType"
      >
        <v-select
          :disabled="isView"
          :width="width"
          :options="buyTypeOps"
          v-model="form.buyType"
        />
      </el-form-item>

      <el-form-item
        label="标题"
        :rules="[{ required: true, message: '请输入标题', trigger: 'blur' }]"
        prop="goodsTitle"
      >
        <v-input
          :disabled="isView"
          :width="width"
          placeholder="请输入标题"
          v-model="form.goodsTitle"
        ></v-input>
      </el-form-item>

      <el-form-item
        v-if="form.buyType == 1"
        label="拼团所需人数"
        :rules="[
          { required: true, message: '请输入拼团所需人数', trigger: 'blur' },
          {
            message: '必须是数字格式',
            pattern: /^[0-9]+$/,
            trigger: 'blur',
          },
        ]"
        prop="groupBookingNeed"
      >
        <v-input
          :disabled="isView"
          :width="width"
          placeholder="请输入拼团所需人数"
          v-model="form.groupBookingNeed"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="商品数量"
        :rules="[
          { required: true, message: '请输入商品数量', trigger: 'blur' },
          {
            message: '必须是数字格式',
            pattern: /^[0-9]+$/,
            trigger: 'blur',
          },
        ]"
        prop="goodsCount"
      >
        <v-input
          :disabled="isView"
          :width="width"
          placeholder="请输入商品数量"
          v-model="form.goodsCount"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="原价"
        :rules="[
          { required: true, message: '请输入原价', trigger: 'blur' },
          {
            message: '必须是数字格式',
            pattern: /^[0-9]\d*(.\d{1,2})?$/,
            trigger: 'blur',
          },
        ]"
        prop="originalCost"
      >
        <v-input
          :disabled="isView"
          :width="width"
          placeholder="请输入原价"
          v-model="form.originalCost"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="现价"
        :rules="[
          { required: true, message: '请输入现价', trigger: 'blur' },
          {
            message: '必须是数字格式',
            pattern: /^[0-9]\d*(.\d{1,2})?$/,
            trigger: 'blur',
          },
        ]"
        prop="nowCost"
      >
        <v-input
          :disabled="isView"
          :width="width"
          placeholder="请输入现价"
          v-model="form.nowCost"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="产品规格"
        :rules="[
          { required: true, message: '请输入产品规格', trigger: 'blur' },
        ]"
        prop="goodsSpecification"
      >
        <v-input
          :disabled="isView"
          :width="width"
          placeholder="请输入产品规格"
          v-model="form.goodsSpecification"
        ></v-input>
      </el-form-item>

      <el-form-item
        :label="`${buyTypeTxt}时间`"
        :rules="[
          {
            required: true,
            message: `请输入${buyTypeTxt}时间`,
            trigger: 'blur',
          },
        ]"
        prop="buyEndTime"
      >
        <v-date-picker
          :disabled="isView"
          v-model="buyTime"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="开始时间"
          endPlaceholder="结束时间"
        />
      </el-form-item>

      <el-form-item
        label="提货时间"
        :rules="[
          { required: true, message: '请输入提货时间', trigger: 'blur' },
        ]"
        prop="getEndTime"
      >
        <v-date-picker
          :disabled="isView"
          v-model="getAddressTime"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="开始时间"
          endPlaceholder="结束时间"
        />
      </el-form-item>

      <el-form-item
        label="提货地点"
        :rules="[
          { required: true, validator: getAddressValid, trigger: 'blur' },
        ]"
        prop="getAddress"
      >
        <div class="address">
          <div
            class="address-input"
            v-for="(item, index) in form.getAddress"
            :key="index"
          >
            <v-input
              :disabled="isView"
              :width="width"
              placeholder="请输入"
              v-model.trim="form.getAddress[index]"
            ></v-input>
            <template v-if="!isView">
              <v-button
                v-if="index == 0"
                text="新增"
                @click="setAddress(item, index, form.getAddress)"
              />
              <v-button
                v-else
                type="warning"
                text="删除"
                @click="setAddress(item, index, form.getAddress)"
              />
            </template>
          </div>
        </div>
      </el-form-item>

      <el-form-item
        label="封面"
        :rules="[
          {
            required: true,
            message: '请上传封面',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="imageUrl"
      >
        <v-upload :disabled="isView" :limit="1" :imgUrls.sync="form.imageUrl" />
      </el-form-item>

      <el-form-item
        label="介绍图片"
        :rules="[
          {
            required: true,
            message: '请上传介绍图片',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="detailsUrl"
      >
        <v-upload
          :disabled="isView"
          :limit="9"
          :imgUrls.sync="form.detailsUrl"
        />
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import moment from "moment";
import { issueGroupBuyingURL, getGroupBuyingDetailsForManaURL } from "./api.js";
import { setBuyTypeOps } from "./map.js";

export default {
  data() {
    return {
      width: 250,
      isView: false,
      submitConfig: {
        queryUrl: getGroupBuyingDetailsForManaURL,
        submitUrl: issueGroupBuyingURL,
      },
      buyTypeOps: setBuyTypeOps(),
      form: {
        id: "",
        buyType: 1,
        goodsTitle: "",
        groupBookingNeed: "",
        goodsCount: "",
        originalCost: "",
        nowCost: "",
        goodsSpecification: "",
        buyStartTime: "",
        buyEndTime: "",
        getStartTime: "",
        getEndTime: 0,
        getAddress: [""],
        imageUrl: "",
        detailsUrl: "",
      },
    };
  },
  computed: {
    buyTime: {
      get() {
        return !this.form.buyStartTime && !this.form.buyEndTime
          ? []
          : [this.form.buyStartTime, this.form.buyEndTime];
      },
      set(val) {
        [this.form.buyStartTime, this.form.buyEndTime] = val || ["", ""];
      },
    },
    getAddressTime: {
      get() {
        return !this.form.getStartTime && !this.form.getEndTime
          ? []
          : [this.form.getStartTime, this.form.getEndTime];
      },
      set(val) {
        [this.form.getStartTime, this.form.getEndTime] = val || ["", ""];
      },
    },
    buyTypeTxt() {
      return this.form.buyType == 1 ? "团购" : "秒杀";
    },
    addressValid() {
      return this.form.getAddress.filter((v) => !!v);
    },
  },
  async mounted() {
    const { id, isView } = this.$route.query;
    this.form.id = id;
    this.isView = !!isView;
    if (id !== undefined) {
      this.$refs.formPanel.getData({ id: id });
    }

    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
      this.form.getAddress = data.getAddress
        ? data.getAddress.split(",")
        : [""];
    },
    // 提货地点
    setAddress(item, index, arr) {
      // 点击第0个元素图片会新增地址，反之删除点击的地址
      if (index == 0) {
        arr.push("");
      } else {
        arr.splice(index, 1);
      }
    },
    // 提货地点验证
    getAddressValid(rule, value, callback) {
      if (!value || !value.join("")) {
        callback(new Error("请输入提货地点"));
      } else {
        callback();
      }
    },
    submitBefore() {
      if (
        moment(this.form.getStartTime.replace(/-/g, "/")).isBefore(
          this.form.buyEndTime.replace(/-/g, "/")
        )
      ) {
        this.$message(`${this.buyTypeTxt}结束时间不能大于提货开始时间`);
        return;
      }
      return {
        ...this.form,
        getAddress: this.addressValid,
        groupBookingNeed:
          this.form.buyType == 1 ? this.form.groupBookingNeed : undefined,
      };
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.section {
  box-sizing: border-box;
  height: 100%;
  .address {
    display: flex;
    flex-direction: column;
    .address-input {
      &:not(:first-child) {
        margin-top: 10px;
      }
      display: flex;
      align-items: center;
      ::v-deep .v-button {
        margin-left: 20px;
      }
    }
  }
}
</style>
