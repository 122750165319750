var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"团购类别","rules":[
        { required: true, message: '请选择团购类别', trigger: 'blur' },
      ],"prop":"buyType"}},[_c('v-select',{attrs:{"disabled":_vm.isView,"width":_vm.width,"options":_vm.buyTypeOps},model:{value:(_vm.form.buyType),callback:function ($$v) {_vm.$set(_vm.form, "buyType", $$v)},expression:"form.buyType"}})],1),_c('el-form-item',{attrs:{"label":"标题","rules":[{ required: true, message: '请输入标题', trigger: 'blur' }],"prop":"goodsTitle"}},[_c('v-input',{attrs:{"disabled":_vm.isView,"width":_vm.width,"placeholder":"请输入标题"},model:{value:(_vm.form.goodsTitle),callback:function ($$v) {_vm.$set(_vm.form, "goodsTitle", $$v)},expression:"form.goodsTitle"}})],1),(_vm.form.buyType == 1)?_c('el-form-item',{attrs:{"label":"拼团所需人数","rules":[
        { required: true, message: '请输入拼团所需人数', trigger: 'blur' },
        {
          message: '必须是数字格式',
          pattern: /^[0-9]+$/,
          trigger: 'blur',
        },
      ],"prop":"groupBookingNeed"}},[_c('v-input',{attrs:{"disabled":_vm.isView,"width":_vm.width,"placeholder":"请输入拼团所需人数"},model:{value:(_vm.form.groupBookingNeed),callback:function ($$v) {_vm.$set(_vm.form, "groupBookingNeed", $$v)},expression:"form.groupBookingNeed"}})],1):_vm._e(),_c('el-form-item',{attrs:{"label":"商品数量","rules":[
        { required: true, message: '请输入商品数量', trigger: 'blur' },
        {
          message: '必须是数字格式',
          pattern: /^[0-9]+$/,
          trigger: 'blur',
        },
      ],"prop":"goodsCount"}},[_c('v-input',{attrs:{"disabled":_vm.isView,"width":_vm.width,"placeholder":"请输入商品数量"},model:{value:(_vm.form.goodsCount),callback:function ($$v) {_vm.$set(_vm.form, "goodsCount", $$v)},expression:"form.goodsCount"}})],1),_c('el-form-item',{attrs:{"label":"原价","rules":[
        { required: true, message: '请输入原价', trigger: 'blur' },
        {
          message: '必须是数字格式',
          pattern: /^[0-9]\d*(.\d{1,2})?$/,
          trigger: 'blur',
        },
      ],"prop":"originalCost"}},[_c('v-input',{attrs:{"disabled":_vm.isView,"width":_vm.width,"placeholder":"请输入原价"},model:{value:(_vm.form.originalCost),callback:function ($$v) {_vm.$set(_vm.form, "originalCost", $$v)},expression:"form.originalCost"}})],1),_c('el-form-item',{attrs:{"label":"现价","rules":[
        { required: true, message: '请输入现价', trigger: 'blur' },
        {
          message: '必须是数字格式',
          pattern: /^[0-9]\d*(.\d{1,2})?$/,
          trigger: 'blur',
        },
      ],"prop":"nowCost"}},[_c('v-input',{attrs:{"disabled":_vm.isView,"width":_vm.width,"placeholder":"请输入现价"},model:{value:(_vm.form.nowCost),callback:function ($$v) {_vm.$set(_vm.form, "nowCost", $$v)},expression:"form.nowCost"}})],1),_c('el-form-item',{attrs:{"label":"产品规格","rules":[
        { required: true, message: '请输入产品规格', trigger: 'blur' },
      ],"prop":"goodsSpecification"}},[_c('v-input',{attrs:{"disabled":_vm.isView,"width":_vm.width,"placeholder":"请输入产品规格"},model:{value:(_vm.form.goodsSpecification),callback:function ($$v) {_vm.$set(_vm.form, "goodsSpecification", $$v)},expression:"form.goodsSpecification"}})],1),_c('el-form-item',{attrs:{"label":`${_vm.buyTypeTxt}时间`,"rules":[
        {
          required: true,
          message: `请输入${_vm.buyTypeTxt}时间`,
          trigger: 'blur',
        },
      ],"prop":"buyEndTime"}},[_c('v-date-picker',{attrs:{"disabled":_vm.isView,"type":"datetimerange","formatValue":"yyyy-MM-dd HH:mm:ss","format":"yyyy-MM-dd HH:mm:ss","startPlaceholder":"开始时间","endPlaceholder":"结束时间"},model:{value:(_vm.buyTime),callback:function ($$v) {_vm.buyTime=$$v},expression:"buyTime"}})],1),_c('el-form-item',{attrs:{"label":"提货时间","rules":[
        { required: true, message: '请输入提货时间', trigger: 'blur' },
      ],"prop":"getEndTime"}},[_c('v-date-picker',{attrs:{"disabled":_vm.isView,"type":"datetimerange","formatValue":"yyyy-MM-dd HH:mm:ss","format":"yyyy-MM-dd HH:mm:ss","startPlaceholder":"开始时间","endPlaceholder":"结束时间"},model:{value:(_vm.getAddressTime),callback:function ($$v) {_vm.getAddressTime=$$v},expression:"getAddressTime"}})],1),_c('el-form-item',{attrs:{"label":"提货地点","rules":[
        { required: true, validator: _vm.getAddressValid, trigger: 'blur' },
      ],"prop":"getAddress"}},[_c('div',{staticClass:"address"},_vm._l((_vm.form.getAddress),function(item,index){return _c('div',{key:index,staticClass:"address-input"},[_c('v-input',{attrs:{"disabled":_vm.isView,"width":_vm.width,"placeholder":"请输入"},model:{value:(_vm.form.getAddress[index]),callback:function ($$v) {_vm.$set(_vm.form.getAddress, index, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.getAddress[index]"}}),(!_vm.isView)?[(index == 0)?_c('v-button',{attrs:{"text":"新增"},on:{"click":function($event){return _vm.setAddress(item, index, _vm.form.getAddress)}}}):_c('v-button',{attrs:{"type":"warning","text":"删除"},on:{"click":function($event){return _vm.setAddress(item, index, _vm.form.getAddress)}}})]:_vm._e()],2)}),0)]),_c('el-form-item',{attrs:{"label":"封面","rules":[
        {
          required: true,
          message: '请上传封面',
          trigger: ['change', 'blur'],
        },
      ],"prop":"imageUrl"}},[_c('v-upload',{attrs:{"disabled":_vm.isView,"limit":1,"imgUrls":_vm.form.imageUrl},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "imageUrl", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "imageUrl", $event)}}})],1),_c('el-form-item',{attrs:{"label":"介绍图片","rules":[
        {
          required: true,
          message: '请上传介绍图片',
          trigger: ['change', 'blur'],
        },
      ],"prop":"detailsUrl"}},[_c('v-upload',{attrs:{"disabled":_vm.isView,"limit":9,"imgUrls":_vm.form.detailsUrl},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "detailsUrl", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "detailsUrl", $event)}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }